<template>
  <add :action="'edit'" :mealItem="item" />
</template>

<script>
import Add from "./add.vue";
import mealItems from "@/requests/dance-festival/menu.js";
import { mapMealItemFromBackend } from "@/helpers/api-mappers/dance-festival/menu.js";

export default {
  components: {
    Add,
  },
  computed: {
    menuId() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    item: null,
  }),
  methods: {
    getMealItem() {
      this.loading = true;
      mealItems
        .get(this.menuId)
        .then(({ data }) => {
          this.item = mapMealItemFromBackend(data.data);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
  created() {
    if (this.menuId) {
      this.getMealItem();
    }
  },
};
</script>
